:root {
  --offset: 0rem;
}

.contact-info .phone-number {
  font-size: 25px!important;

}

.card1 {
  display: block;
  position: relative;
  max-width: 262px;
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #00838d;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
  }

  &:hover:before {
    transform: scale(21);
  }
}
.test{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.card-title {
    color: white;
    font-size: 16px;
    font-weight: 600;
  
}
.card-text {
    color: whitesmoke;
    font-size: 14px;
    
}

@media (min-width:  980px) {
	.row > .col-lg-2 {
    margin-right:35px;
    padding-left:0;
    padding-right:0;
    transform: scale(1);
    opacity: 1;
    transition: all 150ms ease-in-out; 

    &:hover {
      transform: scale(1.15);

      z-index: 10;
    }
  
    &:before {
      transform: scale(5);
      transform-origin: 50% 50%;
      transition: transform 4s ease-out;
    }
  
    &:hover:before {
      transform: scale(1);
    }
	}
}



.card {

  border: solid #fff;

  transform: scale(1);
    opacity: 1;
    transition: all 150ms ease-in-out; 
    

   .card-body {
    background-color: #e9e9e9;
    .card-title {
      color: #000000;
    }

    .card-text {
      color: #000000
    }
    

 
   }
    
    cursor: pointer;
  
  

   
    border-radius: 40px;
    min-height: 210px;

    margin-bottom: 15px;
    z-index: 0;
    overflow: hidden;
   
    --bs-card-border-color:  none;

    
 

}

.section-heading .subtitle {
  color:  var(--color-primary)!important;
}


.card2 {
  display: block;
  top: 0px;
  position: relative;
  

  max-width: 262px;

  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid #f2f8f9;

  &:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #cccccc;
    background-color: white;
  }

  &:before {
    transform: scale(2);
    transform-origin: 50% 50%;
    transition: transform 0.15s ease-out;
  }

  &:hover:before {
    transform: scale(2.15);
  }
}


.test2 {

  
// $offset: var(--offset);
// $cols: 2;
// $n: 8;
// // $cols - number of column
// // n - number of items

// @mixin moveInGrid($cols, $n) {
//   $rows: ceil($n / $cols);
//   .grid {       
//     grid-template-columns: repeat($cols, 1fr);
//     grid-template-rows: repeat($rows, 100% / $rows);
//     li:last-child:after,
//     li:last-child:before {
//       left: 100% * (($cols - $n) % $cols);
//       transform: translate3d(-100% * (($cols - 1) / 2), -100% * (($rows - 1) / 2), 0);
//     }
//     @for $i from 1 through $n - 1 {
//       $x: ($cols - $i) % $cols;
//       $y: $rows - floor(($i - 1) / $cols) - 1;
//         li:nth-child(#{$i}):hover ~ li:last-child:after,
//       li:nth-child(#{$i}):hover ~ li:last-child:before {
//           transform: translate3d(-$x*100%, -$y*100%, 0);   
//         }
//     }
//     li:last-child:hover:after,
//     li:last-child:hover:before {
//       transform: translate3d(-100% * (($cols - $n) % $cols), 0%, 0);  
//     }
//   }
  
// }

// @mixin colors( $n, $colors ) {
//   @for $i from 1 through $n - 1 {
//     li:nth-child(#{$i}):hover ~ li:last-child:after {
//       @if (nth($colors, $i)) {
//         background: nth($colors, $i);
//       }       
//     }
//   }
//   li:last-child:hover:after {
//     background: nth($colors, -1);   
//   }
// }


// *, *:before, *:after {
//   box-sizing: border-box;
// }
// @media (min-width: 40em) {
//   body {
//     display: grid;
//     grid-template-columns: 1fr 3fr;
//   }
// }
// img,
// a {
//     display: block;
//   height: 100%;
// }
// img {
//   max-width: 100%;
//   width: 100%;
//   object-fit: cover;
// }
// .grid {
//   display: grid;
//   grid-gap: 0;
//   overflow: hidden;
//   list-style: none;
//   margin: 0;
//   padding: 0;

//   li {
//     position: relative;
    
//   }
//   li:hover ~ li:last-child:after,
//   li:last-child:hover:after,
//   li:hover ~ li:last-child:before,
//   li:last-child:hover:before {
//     opacity: 1;
    
//     transition: 1s ease;
//   }
//   li:last-child:after,
//   li:last-child:before {
    
//     content: "";
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     top: 0;
//     pointer-events: none;
//     clip-path:polygon($offset $offset, $offset calc(100% - #{$offset}), calc(100% - #{$offset}) calc(100% - #{$offset}), calc(100% - #{$offset}) $offset);
//     -webkit-clip-path:polygon($offset $offset, $offset calc(100% - #{$offset}), calc(100% - #{$offset}) calc(100% - #{$offset}), calc(100% - #{$offset}) $offset);
//     opacity: 0;
//     transition: opacity 1s, transform 1s 1s, background 1s;
//   }
 
//   li:last-child:after {
    
//     mix-blend-mode: color;
//     background: #e2e2e2;
//   }
//   li:last-child:before {
    
//     backdrop-filter: grayscale(100%) blur(3px);
//     -webkit-backdrop-filter: grayscale(100%) blur(3px);
//   }
//   //colors (optional)
//   @include colors(8,[#e2e2e2, #e2e2e2, #e2e2e2, #e2e2e2, #e2e2e2, #e2e2e2, #e2e2e2, #e2e2e2]);
// }



// @include moveInGrid(4, 8);

// @media (max-width: 60em) {
  
//   @include moveInGrid(1, 8);
//   :root {
    
//     --offset: 0;
//   }
// }

// .description {

//   font-size: 1.25rem;
//   position: absolute;
//   display: block;
//   right: 55%;

//   text-align: center;
//   top: 50%;
//   width: 60px;
//   margin: auto;
//   color: white;
//   z-index: 100;
//   opacity: 0;
//   transform: translate3d(0, -50%, 0);
//   transition: .3s 0s;
// }

// li:hover .description {
  
//   opacity: 1; 
//   transition: .6s .3s;
// }

  &:hover .card3 {
    opacity: 1;-webkit-filter: blur(0);filter: blur(0);
    }

  .card3 {
    display: block;
    top: 0px;
    position: relative;

    border-radius: 30px;
    margin: 0;
 

    p{

      visibility: hidden;
      opacity: 0;
      transition: all 1s ease-out;
      position: absolute;
      top: 40%;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      color: #fff;
      padding: 20px 20px;
      margin: 0;
     
    } 
    p:hover{
      display: block;
    }
  
    &:hover {
      p {
        visibility: hidden;
  // opacity: 1;
  // transition: all 0.5s ease-in;
 
      }
      opacity:0.6; filter: invert(9%) brightness(89%) contrast(84%) blur(0px);
      
    }
  }

}


.row-45 {

  // &:hover .card3 {opacity:.8; -webkit-filter: blur(5px); filter: blur(5px);}

  .card3 {
    display: block;
    top: 0px;
    position: relative;

   
   
  
    margin: 0;
 
  
    #number0{
      border-radius: 10px 0 0 0 ;
    }
    #number1{
      border-radius: 0 10px 0 0 ;
    }
    #number3{
      border-radius: 0 0 10px 0;
    }
    #number2{
      border-radius: 0 0 0 10px;
    }

 
    p{
      
      display: none;
      color: #fff;
      padding: 20px 20px;
      margin: 0;
     
    } 
    p:hover{
      display: block;
    }
  
    &:hover {
      opacity: 0.5;
      
    }
  }

}


.row > .col-md-6  {
  padding-left: 0;
  padding-right: 0;
}
.card3:hover {
  p{
    color: #fff;

  }
 
}

.slick-dots {
 bottom: -100px!important;
}

figure.snip1157 {
  border-radius: 20px;
 background-color: #fff;
  position: relative;
  overflow: hidden;
  margin: 10px;
  min-width: 220px;
  max-width: 310px;
  width: 100%;
  color: #333;
  text-align: left;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}
figure.snip1157 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  transition: all 0.35s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  

}
figure.snip1157 img {


  border-radius: 50%;
  margin: 40px auto 0 auto;
  /* make a square container */
  width: 150px;
  height: 150px;

  /* fill the container, preserving aspect ratio, and cropping to fit */
  background-size: cover;

  /* center the image vertically and horizontally */
  background-position: top center;

  /* round the edges to a circle with border radius 1/2 container size */
  border-radius: 50%;
}
figure.snip1157 blockquote {
  display: block;
  border-radius: 8px;


  padding: 25px 50px 30px 50px;
  font-size: 1em;
  font-weight: 500;

  line-height: 1.6em;
  font-style: italic;



  color: #000000;
  font-family: var(--font-primary);
  letter-spacing: -0.025em;

}
figure.snip1157 blockquote:before,
figure.snip1157 blockquote:after {
 
  content: "\201C";

  font-size: 50px;
  opacity: 0.3;
  font-style:normal;

}
figure.snip1157 blockquote:before {

}
figure.snip1157 blockquote:after {
  display: none;

 
}

figure.snip1157 .author {
 
  bottom: 45px;
  margin: 0px auto 50px auto;
  text-align: center;

 
  color: #ffffff;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}
figure.snip1157 .author h6 {
  font-weight: 500;
    color: var(--color-text-dark);
    margin:0 !important;
    line-height: var(--line-height-b1); 
}
figure.snip1157 .author span {
  font-weight: 200;
  font-size: 0.9em;

  color: var(--color-gray-2);
  
}





.container2 {
  height: 100%;
  width: 100%;
  padding: 1rem 0;
  place-content: center;
  
  .cards {    

    grid-template-columns: repeat(2, 1fr);

    pointer-events: none;
  }
  
  .card4 {
    border-radius: 10px;
    max-width: 100%;
    color: #ffffffda;
    padding: 2.5rem;
    background-color: var(--color-primary);
    margin-bottom: 30px;
    
    pointer-events: auto;
    
    transform: scale(1);
    opacity: 1;
    transition: all 150ms ease-in-out; 

    
    .card-title {
      position: relative;
      margin-bottom: 1.5rem;
      font-size: 2rem;
      
      &::before {
        background-image: url("/images/logo.png");
        height: 1rem;
        width: 1rem;
        border-radius: 50%;
        box-shadow: inset 0 0 3px var(--color-light);
        background-color: transparent;
        
        position: absolute;
        right: 0;
      }
    }
    
    .date {
      color: #bb86fc;
      font-size: 0.85rem;
      margin-bottom: 1.5rem;
    }
    
    .description {
      font-size: 0.9rem;
    }
    
  
  }
}

.cards:hover > .row > .col-lg-6 > .card4:hover {
  transform: scale(1.15);
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  z-index: 10;
}
.cards:hover > .row > .col-lg-6 > .card4:not(:hover) {
  opacity: 0.5;
}


.test3 {

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

