/*-------------------------
   Project
-------------------------*/
.project-grid {
    box-shadow: 0 55px 86px -35px #ECF2F6;
    border-radius: 30px;
    margin-bottom: 40px;
    .thumbnail {
        overflow: hidden;
        border-radius: 30px 30px 0 0;
        position: relative;
        width: 100%;
        height: 12vw;
        object-fit: cover;

        @media #{$sm-layout} {
            height: 45vw;
        }
        img {
            border-radius: 30px 30px 0 0;
            width: 100%;
            transition: var(--transition);
            transform: scale(1);
            transition: transform 2s cubic-bezier(0.2, 0.96, 0.34, 1);
        }
    }
    .content {
        background-color: var(--color-white);
        border-radius: 0 0 30px 30px;
        padding: 40px 50px;
        @media only screen and (max-width: 1199px) {
            padding: 40px;
        }
        @media #{$sm-layout} {
            padding: 30px;
        }
        .title {
            letter-spacing: -0.045em;
            margin-bottom: 10px;
            a {
                &:hover {
                    color: var(--color-link);
                }
            }
        }
        .subtitle {
            span {
                margin-right: 5px;
                position: relative;
                &:after {
                    content: ",";
                }
                &:last-child {
                    margin-right: 0;
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
    &:hover {
        .thumbnail {
            img {
                transform: scale(1.2);
            }
        }
    }
    &.project-style-2 {
        box-shadow: none;
        position: relative;
        margin-bottom: 60px;
        .thumbnail {
            border-radius: 30px;
            img {
                border-radius: 30px;
            }
        }
        .content {
            background-color: transparent;
            border-radius: 0;
            padding: 0;
            position: absolute;
            top: 50px;
            left: 50px;
            @media #{$md-layout} {
                top: 30px;
                left: 30px; 
            }
            .subtitle {
                font-size: 18px;
                margin-bottom: 15px;
            }
        }
    }
}

.more-project-btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    @media #{$sm-layout} {
        margin-top: 0;
    }
    .axil-btn {
        padding: 24px 80px;
        color: var(--color-dark);
        display: block;
        &.btn-fill-primary {
            color: var(--color-white);
        }
        @media #{$sm-layout}{
            padding: 18px 40px;
            font-size: 15px;
        }
    }
}








.project-add-banner {






    img {
        

        @media #{$large-mobile} {
            border-radius: 10px;
        }
    
  right: 0;
  bottom: 0;
  min-width: 100%; 
  min-height: 100%;
  background:red;
        background-color: transparent;
        background-image: linear-gradient(180deg, #121B1D85 60%, #121B1D 100%);
        opacity: 1;
        transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
        max-height:75vh;
 
     
    }


 


  
    
    .content {
        @media #{$large-mobile} {
            display:none;
        }
        color:#fff;
     
        padding: 30px;
     
        @media #{$large-mobile} {
            padding-right: 0;
        }
        h3{
            color:#fff;
        }
        .subtitle {
            font-size: var(--font-body-1);
            margin-bottom: 15px;
        }
        .title {
            width: 80%;
            letter-spacing: -0.045em;
            margin-bottom: 110px;
            
            @media #{$smlg-device} {
                
            }
            @media #{$md-layout} {
               
            }
        }
       
    }
    .thumbnail {
        border-radius: 10px;
        max-width: 10px;
        

        
        @media #{$smlg-device} {
            width: 50%;
            padding: 0px;
            border-radius: 10px;
        }
        // @media #{$sm-layout} {
        //     width: 30%;
        // }
        // @media #{$large-mobile} {
        //     margin: 40px auto 0;
        //     width: 70%;
        // }

    }
}


// .cards:hover > .row > .col-lg-6 > .card4:hover {
    
//   }
//   .cards:hover > .row > .col-lg-6 > .card4:not(:hover) {
//     opacity: 0.5;
//   }

  .row:hover > .col-lg-4 > .project-add-banner2 > .service1:hover {
    transform: scale(1.15);
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    z-index: 10;
  }

  .row:hover > .col-lg-4 > .project-add-banner2 > .service1:not(:hover) {
    opacity: 0.5;
  }
  

.project-add-banner2 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    min-height: 510px;
    

    .service1 {
        pointer-events: auto;
    
    transform: scale(1);
    opacity: 1;
        transition: all 150ms ease-in-out;
      
        &:hover{
            transform: scale(1.15);
            box-shadow: 0 0 10px rgba(0,0,0,0.5);
            z-index: 10;
        }
      
        flex: 1;
    
  
    border-radius: 10px;
    position: relative;
    padding-top: 75px;
    padding-bottom: 55px;
    max-width: 500px;
    min-width: 290px;
    margin: 45px 15px 55px;

    }

    .service2 {
        transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
        border-color: rgb(65, 141, 141);
        border-style: solid;
        &:hover{
            border-color: rgb(23, 102, 102);
            border-style: solid;
        }
        flex: 1;
    
  
    border-radius: 10px;
    position: relative;
    padding-top: 75px;
    padding-bottom: 55px;
    max-width: 500px;
    min-width: 290px;
    margin: 45px 15px 55px;

    }

    .service3 {
        transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
        border-color: rgb(24, 47, 68);
        border-style: solid;
        &:hover{
            border-color: rgb(32, 65, 99);
            border-style: solid;
        }
        flex: 1;
    
  
    border-radius: 10px;
    position: relative;
    padding-top: 75px;
    padding-bottom: 55px;
    max-width: 500px;
    min-width: 290px;
    margin: 45px 15px 55px;

    }

    

    .test5{
        position: absolute;
    width: 170px;
    display: flex;

    top: -125px;
    left: 50%;
    margin-left: -85px;
    background-color: #e9e9e9;
  
    }

    .titt {
        max-width: 270px;
    margin: 0 auto;

    color: white;
    }

    .text {
        margin: 15px auto 0;
    max-width: 270px;
   
    color: white;


    }

    
    
    




    // img {

    //     @media #{$large-mobile} {
    //         display:none;
    //     }
    //     max-height: 200px;
    //     opacity: 0.7;
    //     border-radius: 0 10px 10px 0;
    // }


 
    
  
    
    .content {

        width: 600px;

        @media #{$large-mobile} {
            
        }
        color:#fff;
     
        padding: 30px;
     
        @media #{$large-mobile} {
            padding-right: 0;
        }
        h3{
            color:#fff;
        }
        .subtitle {
            font-size: var(--font-body-1);
            margin-bottom: 15px;
        }
        .title {
            width: 80%;
            letter-spacing: -0.045em;
            margin-bottom: 110px;
            
            @media #{$smlg-device} {
                
            }
            @media #{$md-layout} {
               
            }
        }
       
    }
    .thumbnail {
        border-radius: 10px;
        max-width: 10px;
        margin-right: 0;

        
        @media #{$smlg-device} {
            width: 50%;
            padding: 0px;
            border-radius: 10px;
        }
        // @media #{$sm-layout} {
        //     width: 30%;
        // }
        // @media #{$large-mobile} {
        //     margin: 40px auto 0;
        //     width: 70%;
        // }

    }
}


.isotope-project-btn {
    margin-bottom: 55px;
    button {
        border: none;
        background-color: transparent;
        font-size: 20px;
        font-weight: 500;
        color: var(--color-body);
        font-family: var(--font-primary);
        margin-right: 100px;
        padding: 8px 0;
        position: relative;
        @media #{$large-mobile} {
            margin-right: 50px;
            font-size: 18px;
        }
        &:focus-visible {
            outline: none;
        }
        &:after {
            content: "";
            height: 2px;
            width: 100%;
            background-color: var(--color-primary);
            position: absolute;
            bottom: 0;
            left: 0;
            visibility: hidden;
            opacity: 0;
            transition: var(--transition);
        }
        &.is-checked {
            color: var(--color-dark);
            &:after {
                visibility: visible;
                opacity: 1;
            }
        }
        &:last-child {
            margin-right: 0;
        }
    }
}

.single-portfolio-area {
    .why-choose-us {
        @media #{$md-layout} {
            margin-top: 50px;
        }
    }
}

.project-column-4 {
    .project-grid {
        .content {
            padding: 40px 30px;
            .title {
                font-size: 24px;
            }
        }
    } 
}